<template>
  <div class="bookmaster-wraper">
    <app-modal-confirm
      ref="apikey_modal_save"
      :modal-header-title="$t('common_vn.delete confirm title')"
      @confirmed="createOrUpdate()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("web_setting.confirm_update_setting") }}</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >

      <form class="form-horizontal">
        <div class="row">
          <div class="col-12">
            <div class="card card-body">
              <template v-if="idEditing">
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
                  <label class="col-xl-1 col-lg-2 col-sm-2 m-t-8">{{ entry.id }}</label>
                </div>
              </template>

              <!-- Web title-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.home_title") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="home_title"
                    type="string128"
                    v-model="entry.home_title"
                  />
                </div>
              </div>

              <!-- Web title VN -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.home_title_vn") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="home_title_vn"
                    type="string128"
                    v-model="entry.home_title_vn"
                  />
                </div>
              </div>

              <!-- Web description-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.home_description") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-textarea
                    name="home_description"
                    type="string"
                    v-model="entry.home_description"
                  />
                </div>
              </div>

              <!-- Web description VN -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.home_description_vn") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-textarea
                    name="home_description_vn"
                    type="string"
                    v-model="entry.home_description_vn"
                  />
                </div>
              </div>

              <!-- Hotline-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.hotline") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="hotline"
                    type="digits"
                    v-model="entry.hotline"
                  />
                </div>
              </div>

              <!-- logo image -->
              <div class="form-group row ">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.logo_path") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-single-file-input accept="image/jpeg, image/png, image/jpg" name="logo"
                    :imageSrc="entry.logo_url"
                    @input="handlerUploadLogoImage"
                    :allowedExtension="allowedExtension"
                    :rules="{
                      width: 2048,
                      height: 2048
                    }"
                  >
                  </app-single-file-input>
                </div>
              </div>

              <!-- favicon -->
              <div class="form-group row ">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.favicon_path") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-single-file-input accept="image/jpeg, image/png, image/jpg, image/x-icon, image/vnd.microsoft.icon" name="favicon"
                    :imageSrc="entry.favicon_url"
                    @input="handlerUploadFavicon"
                    :allowedExtension="allowedExtension"
                    :rules="{
                      width: 500,
                      height: 500
                    }"
                  >
                  </app-single-file-input>
                </div>
              </div>

              <!-- link fb-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.link_fb") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="link_fb"
                    type="string128"
                    v-model="entry.link_fb"
                  />
                </div>
              </div>

              <!-- link youtube-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.link_youtube") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="link_youtube"
                    type="string128"
                    v-model="entry.link_youtube"
                  />
                </div>
              </div>

              <!-- link instagram-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.link_insta") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="link_insta"
                    type="string128"
                    v-model="entry.link_insta"
                  />
                </div>
              </div>

              <!-- link tiktok-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.link_tiktok") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="link_tiktok"
                    type="string128"
                    v-model="entry.link_tiktok"
                  />
                </div>
              </div>

              <!-- link ggmap-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.link_ggmap") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="link_ggmap"
                    type="string128"
                    v-model="entry.link_ggmap"
                  />
                </div>
              </div>

              <!-- email-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.email") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="email"
                    type="email"
                    v-model="entry.email"
                  />
                </div>
              </div>

              <!-- brand name-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.brand_name") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="brand_name"
                    type="string128"
                    v-model="entry.brand_name"
                  />
                </div>
              </div>

              <!-- company_name-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.company_name") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="company_name"
                    type="string128"
                    v-model="entry.company_name"
                  />
                </div>
              </div>

              <!-- company_address-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.company_address") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="company_address"
                    type="string128"
                    v-model="entry.company_address"
                  />
                </div>
              </div>

              <!-- company_address VN -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.company_address_vn") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="company_address_vn"
                    type="string128"
                    v-model="entry.company_address_vn"
                  />
                </div>
              </div>

              <!-- company_tax_id-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.company_tax_id") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="company_tax_id"
                    type="string128"
                    v-model="entry.company_tax_id"
                  />
                </div>
              </div>

              <!-- line user ids -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("web_setting.line_user_id") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <div v-for="(userId, index) in entry.line_user_ids" class="mb-3">
                    <div class="d-flex">
                      <app-input
                        style="flex: 1;"
                        name="line_user_id"
                        type="string128"
                        v-model="entry.line_user_ids[index]"
                        rules="required"
                      />
                      <button type="button" class="btn btn-danger" style="min-width: fit-content !important; height: 40px !important;" @click="removeLineUserId(index)"><i class="fa fa-trash" aria-hidden="true"></i></button>
                    </div>
                  </div>
                  <button type="button" class="btn btn-primary" @click="addNewLineUserId">Thêm LINE User ID</button>
                </div>
              </div>

              <div class="card-footer row justify-content-end">
                <button :disabled="!valid && validated" class="btn btn-primary m-r-10" type="button" @click="handleSaveSetting" >
                  {{  $t('common_vn.update') }}
                </button>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import moment from 'moment';
import AppTextarea from '@components/forms/AppTextarea.vue';
import { isNull } from 'lodash';
export default {
  name: "WebSetting",

  components: {
    AppTextarea
  },

  data() {
    return {
      entry: {
        id: null,
        home_title: '',
        home_title_vn: '',
        home_description: '',
        home_description_vn: '',
        hotline: '',
        logo_path: null,
        logo_url: null,
        is_new_logo: false,
        favicon_path: null,
        favicon_url: null,
        is_new_favicon: false,
        link_fb: '',
        link_zalo: '',
        link_youtube: '',
        link_insta: '',
        link_tiktok: '',
        link_ggmap: '',
        email: '',
        brand_name: '',
        company_name: '',
        company_address: '',
        company_address_vn: '',
        company_tax_id: '',
        line_user_ids: [],
      },
      idEditing: null,
      allowedExtension : ['image/x-icon', 'image/vnd.microsoft.icon', 'image/jpeg', 'image/png', 'image/jpg']
    }
  },

  methods: {
    async getWebSetting() {
      let response = await this.$request.get(this.ENDPOINT.KYY_WEB_SETTING);
      if (response.hasErrors()) {
        if (response.status === 'error'){
          this.__noticeError(this.$t(response.data.error_msg));
        }
      } else {
        if (response.data.data) {
          this.entry = _.cloneDeep(response.data.data);
          this.entry.is_new_logo = false;
          this.entry.is_new_favicon = false;
        }
      }
    },

    handleSaveSetting() {
      this.$refs.apikey_modal_save.show();
    },

    async createOrUpdate() {
      let res = null;
      let msg = "";
      let params = _.cloneDeep(this.entry);
      res = await this.$request.post(this.ENDPOINT.KYY_SAVE_WEB_SETTING, params);
      msg = this.$t("common_vn.save_ok")
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      } else {
        this.$refs.apikey_modal_save.hide();
        this.__noticeSuccess(msg);
        this.getWebSetting()
      }
    },

    handlerUploadLogoImage(data) {
      this.entry.logo_path = data
      this.entry.logo_url = data
      this.entry.is_new_logo = true
    },

    handlerUploadFavicon(data) {
      this.entry.favicon_path = data
      this.entry.favicon_url = data
      this.entry.is_new_favicon = true
    },

    addNewLineUserId() {
      this.entry.line_user_ids.push('');
    },

    removeLineUserId(index) {
      this.entry.line_user_ids.splice(index, 1)
    }
  },

  mounted: function () {
    this.getWebSetting()
  },
}
</script>
